

























































    import { Mixins, Component } from 'vue-property-decorator';

    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiLiveLabel from '@/styleguide/elements/UiLiveLabel.vue';
    import PlayerExpand from '@/components/player/PlayerExpand.vue';
    import Flex from '@/components/shared/Flex.vue';
    import PlayerCarousel from '@/components/player/shared/PlayerCarousel.vue';
    import ProgressBarOndemand from '@/components/player/progress-bar/ProgressBarOndemand.vue';
    import PlayerExpandedControls from '@/components/player/PlayerExpandedControls.vue';
    import PlayerAudioQuality from '@/components/player/PlayerAudioQuality.vue';
    import PlayerAudioVolume from '@/components/player/PlayerAudioVolume.vue';
    import PlayerAutoPlayToggle from '@/components/player/PlayerAutoPlayToggle.vue';
    import ProgressBarLive from '@/components/player/progress-bar/ProgressBarLive.vue';
    import PlayerSeekToLive from '@/components/player/PlayerSeekToLive.vue';
    import StatusMessage from '@/components/shared/StatusMessage.vue';
    import ScrollToSettings from '@/components/player/ScrollToSettings.vue';
    import Ellipsis from '@/components/shared/Ellipsis.vue';

    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import PlayerProgressMixin from '@/components/mixins/PlayerProgressMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';

    import { AriaLabels } from '@/enums/AriaLabels';
    import keyCodes from '@/enums/key-codes';
    import keyboardTrapping from '@/directives/keyboard-trapping';
    import Icon from '@/components/shared/Icon.vue';
    import deviceDetection from '@/common/device-detection';
    import { LegacyLivePlayerGetter } from '@/enums/LegacyLivePlayerGetter';
    import { EmitEvents } from '@/enums/EmitEvents';
    import { PlayerExpandedEvent } from '@/common/custom-events/player-interaction/player-expanded-event';
    import { PlayerCollapsedEvent } from '@/common/custom-events/player-interaction/player-collapse-event';

    @Component({
        components: {
            UiContainer,
            UiHeading,
            UiCaption,
            UiLiveLabel,
            PlayerExpand,
            Flex,
            Icon,
            PlayerCarousel,
            ProgressBarOndemand,
            ProgressBarLive,
            PlayerExpandedControls,
            PlayerAudioQuality,
            PlayerAudioVolume,
            PlayerAutoPlayToggle,
            PlayerSeekToLive,
            StatusMessage,
            ScrollToSettings,
            Ellipsis
        },
        directives: {
            keyboardTrapping
        }
    })
    export default class PlayerExpanded extends Mixins(MetadataMixin, PlayerProgressMixin, PlayerStateMixin, PlaybackMixin) {
        scrollPositionBeforeMounted: number = 0;
        innerHeight: number = 0;
        ariaLabels = AriaLabels;

        private get isSmartphoneOrTablet(): boolean {
            return deviceDetection.isSmartphoneOrTablet;
        }

        private get playerHeading(): string | null {
            if (this.playlistTitle) {
                return this.playlistTitle;
            }

            return this.currentAudio.channelName || null;
        }

        private get showSeekToLive(): boolean {
            if (!this.currentAudio?.endTime) {
                return false;
            }

            return this.currentAudio.endTime <= this.$store.getters[LegacyLivePlayerGetter.LivePositionTime];
        }

        get heightCss(): string {
            return `height: ${this.innerHeight}px`;
        }

        private close(): void {
            this.$emit(EmitEvents.Close);
        }

        private keyHandler(event: KeyboardEvent): void {
            if (event.keyCode === keyCodes.escape) {
                this.close();
            }
        }

        private onScrollToSettingsClick(): void {
            (this.$refs.settings as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        }

        created(): void {
            this.scrollPositionBeforeMounted = window.pageYOffset;
            window.dispatchEvent(new PlayerExpandedEvent(this.currentAudio, this.playlistTitle));
            document.addEventListener('keydown', this.keyHandler, true);
            this.innerHeight = window.innerHeight;
        }

        mounted(): void {
            if (this.isSmartphoneOrTablet) {
                window.scrollTo(window.scrollX, 0);
            }
            (this.$refs.closeButton as HTMLElement).focus();
        }

        destroyed(): void {
            window.dispatchEvent(new PlayerCollapsedEvent());
            this.$nextTick().then(() => {
                window.scrollTo(window.scrollX, this.scrollPositionBeforeMounted);
            });
            document.removeEventListener('keydown', this.keyHandler, true);
        }
    }
