export default {
    tab: 9,
    enterKey: 13,
    escape: 27,
    leftArrow: 37,
    upArrow: 38,
    rightArrow: 39,
    downArrow: 40,
    num7: 55,
    num9: 57,
    num8: 56,
    P: 80,
    numPad7: 103,
    numPad9: 105,
    numPad8: 104,
    plus: 187,
    numPadPlus: 107,
    minus: 189,
    numPadMinus: 109,
    mouseDefault: 1
};
